<template>
    <v-main>
        <v-container>
            <div class="text-h4 mb-6">IT Inventory</div>
            <SearchEndpoints
                v-model="foundEndpoint"
                label="Search IT Inventory"
                prepend-inner-icon="mdi-magnify"
                :show-loader="false"
                with-user-location
                rounded
                outlined
            />
            <v-expand-transition>
                <v-list
                    v-if="foundEndpoint"
                    style="margin: 0 auto"
                    class="mt-7"
                    width="800"
                    outlined
                    rounded
                >
                    <v-list-item>
                        <v-list-item-icon>
                            <v-icon x-large>{{ endpointIcon[foundEndpoint.endpoint_type] || 'mdi-crosshairs-question' }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title class="text-h5 grey--text text--darken-3">{{ foundEndpoint.name }}</v-list-item-title>
                            
                            <v-list-item-subtitle class="mb-3">
                                <v-chip
                                    v-text="foundEndpoint.endpoint_status"
                                    :color="endpointStatus[foundEndpoint.endpoint_status]"
                                    small
                                    outlined
                                />
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-1" v-if="foundEndpoint.last_checkin">
                                <v-icon left small>mdi-update</v-icon><span :title="foundEndpoint.last_checkin">{{ getDateDistanceIso(foundEndpoint.last_checkin) }} ago</span>
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-1" v-if="foundEndpoint.location_name">
                                <v-icon left small>mdi-map-marker</v-icon> {{ foundEndpoint.location_name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle class="mb-1" v-if="foundEndpoint.primary_user">
                                <v-icon left small>mdi-account</v-icon> {{ foundEndpoint.primary_user.name }}
                            </v-list-item-subtitle>
                            <v-list-item-subtitle v-if="foundEndpoint.division">
                                <v-icon left small>mdi-home</v-icon> {{ foundEndpoint.division }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-btn
                                :to="{name: 'it/inventory/endpoint', params: {endpointId: foundEndpoint.pk}}"
                                small
                                rounded
                            >View More Details</v-btn>
                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-expand-transition>
            <v-divider class="mt-7 mb-7" />
            <template v-if="stats">
                <div class="text-h5 mb-3 orange--text text--darken-2">{{ stats.checkin.daily.all }} daily check-ins</div>
                <v-row>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <v-icon left x-large>mdi-desktop-classic</v-icon>
                                {{ stats.checkin.daily.computer }} Computers
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <v-icon left x-large>mdi-monitor</v-icon>
                                {{ stats.checkin.daily.thinClient }} Thin Clients
                            </v-card-title>
                        </v-card>
                    </v-col>
                    <v-col>
                        <v-card>
                            <v-card-title>
                                <v-icon left x-large>mdi-printer</v-icon>
                                {{ stats.checkin.daily.printer }} Printers
                            </v-card-title>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
        </v-container>
    </v-main>
</template>
<script>
import axios from 'axios';
import dateTimeHelper from '@/composables/common/dateTime';
import SearchEndpoints from '@/components/it/inventory/SearchEndpoints';
import { ref, onMounted } from '@vue/composition-api';
export default {
    name: 'Index',
    components: {
        SearchEndpoints
    },
    setup(){
        const stats = ref(null);
        const foundEndpoint = ref(null);
        const { getDateDistanceIso } = dateTimeHelper();
        onMounted(async () => {
            const response = await axios.get(`/inventory/load_checkin_stats/`);
            stats.value = response.data;
        });
        return {
            stats,
            foundEndpoint,
            getDateDistanceIso
        }
    },
};
</script>